import ShortUrl from 'api/ShortUrl'
import { DEFAULT_URL, WHITELIST_URL_FUNCTIONALITIES_PROVIDERS } from 'utils/Constants/CreatePostConstants'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostData, IPostVariant, IUrl } from 'types/PostInterface'
import { AppStore, T, showWarning } from 'utils'
import {
  getActiveDescription,
  getActiveInfo,
  getActiveText,
  getFirstCommentText,
  getPostData,
  updateActive,
  updatePost,
} from './Post'
import { isValidUrl } from 'utils/NetworkUtils'

//Metadati - UTM - Shortlink
export const findFirstDiff = (string, string2) => {
  const stringWords = string
  const string2Words = string2

  let index = 0
  for (const word of string2Words) {
    if (word !== stringWords[index]) {
      let diff = ''
      for (let i = index; i < string2Words.length; i++) {
        diff += string2Words[i]
        if (string2Words[i] === ' ' || string2Words[i] === '\n') {
          break
        }
      }
      return diff
    }
    index++
  }

  return ''
}

export const onPasteLink = async (config: {
  activePost: IPostVariant | IPostData
  originalUrl?: string
  newText: string
  firstComment?: boolean
  isDescription?: boolean
}) => {
  const { activePost, newText, firstComment, isDescription } = config

  if (activePost === undefined) return
  const postData = getPostData(activePost)

  let originUrl

  if (firstComment === undefined && !originUrl) {
    originUrl = config?.originalUrl ?? findFirstDiff(postData.text, newText).replace(/^\n+|\n+$/g, '')
  }

  if (firstComment === true) {
    originUrl = findFirstDiff(postData.firstComment?.text ?? '', newText).replace(/^\n+|\n+$/g, '')
  }

  // verifica l'url sia valido
  if (!isValidUrl(originUrl)) return
  useCreatePostStore.setState({ textLoading: false })

  // const results = await manageShorturl(originUrl)
  // if (results === undefined) return showWarning(T.warning.notValidUrl)
  // Sostituisce "originUrl" con "results.shortUrl" in copyDescription
  try {
    // ----------- Check if is already a shortened link -----------
    const currentUrl = postData.urls?.find((url) => url.originalUrl === originUrl)
    if (currentUrl) {
      const updatedCopyDescription = newText.replace(originUrl, currentUrl.shortUrl)

      //Testo normale (sezione preview)
      if (firstComment === undefined) {
        updateActive({ text: updatedCopyDescription })
      }

      //Testo im first comment (sezione extra)
      if (firstComment === true) {
        if ('variantId' in activePost) {
          updatePost(activePost.variantId, { firstComment: { text: updatedCopyDescription } })
        }
      }
      useCreatePostStore.setState({ textLoading: false })
      return
    }

    // ----------- If it's not a shortened link -----------
    const newUrlProperty: any = await recoverShortenedLink(originUrl, 'shorten')
    const updatedCopyDescription = newText.replace(originUrl, newUrlProperty?.shortUrl)

    //Testo normale (sezione preview)
    if (firstComment === undefined) {
      const updateVisibilyMetadatas =
        postData.urls?.length > 0
          ? postData.urls?.map((url) => ({
              ...url,
              metaData: { ...url.metaData, visible: false },
            }))
          : []

      const updateValue = !isDescription ? { text: updatedCopyDescription } : { description: updatedCopyDescription }
      updateActive({ ...updateValue, urls: [...updateVisibilyMetadatas, newUrlProperty] })
    }

    //Testo im first comment (sezione extra)
    if (firstComment === true) {
      if ('variantId' in activePost) {
        const updateVisibilyMetadatas =
          postData.firstComment?.urls?.length > 0
            ? postData.firstComment.urls?.map((url) => ({
                ...url,
                metaData: { ...url.metaData, visible: false },
              }))
            : []

        updatePost(activePost.variantId, {
          firstComment: { text: updatedCopyDescription, urls: [...updateVisibilyMetadatas, newUrlProperty] },
        })
      }
    }
  } catch (error) {
    console.error('error adding ref parameter to the url', error)
  }
  useCreatePostStore.setState({ textLoading: false })
}

export const recoverShortenedLink = async (originUrl: string, type: 'shorten' | 'standard', ref?: string) => {
  try {
    const results = await ShortUrl.getShortLinkAndMetadata(originUrl, type, ref)

    if (results === undefined) return showWarning(T.warning.notValidUrl)

    const utmConfig = {
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_term: '',
      utm_content: '',
    }

    const metaData = {
      ogUrl: results?.metaData?.ogUrl,
      ogSiteName: results?.metaData?.ogSiteName,
      ogTitle: results?.metaData?.ogTitle,
      ogDescription: results?.metaData?.ogDescription,
      ogImg: results?.metaData?.ogImg,
      visible: true,
    }

    const newUrlProperty: IUrl = {
      id: results._id,
      shortUrl: results.shortUrl,
      originalUrl: results.origUrl,
      shortened: true,
      utmConfig,
      metaData,
    }

    return newUrlProperty
  } catch (error) {
    console.error('error adding ref parameter to the url', error)
  }
}

// Ritorna il primo metadata visible
export const findVisibleMetadata = (post: IPostData | IPostVariant, firstComment?: boolean): any => {
  if (post === undefined) return

  if (firstComment === undefined) {
    if ('_id' in post && post._id === 'GLOBAL')
      return (post as IPostData).urls?.filter((url) => url?.metaData?.visible ?? true)
    if ('variantId' in post) return post.data.urls?.filter((url) => url?.metaData?.visible ?? true)
  }

  if (firstComment === true) {
    if ('variantId' in post) return post.data.firstComment?.urls?.filter((url) => url?.metaData?.visible ?? true)
  }

  return []
}

// Di base ritorna l'ultimo url che trova
export const findWorkingUTM = (post: IPostData | IPostVariant, selctedIndex: number, firstComment?: boolean) => {
  if (firstComment === undefined) {
    if ('variantId' in post) return post.data.urls && post.data.urls[selctedIndex]
    if ('_id' in post && post._id === 'GLOBAL') return post.urls && post.urls[selctedIndex]
  }

  if (firstComment === true) {
    if ('variantId' in post) return post.data.firstComment?.urls && post.data.firstComment?.urls[selctedIndex]
  }

  return DEFAULT_URL
}

// Ritorna link completo con i valori utm
export const newUtmUrl = (visibleLink: IUrl, utmValues: any) => {
  try {
    const origUrl = visibleLink.originalUrl ?? ''
    // check if url already has utm params then replace them with the key
    const url = new URL(origUrl)
    const params = new URLSearchParams(url.search)
    const keys = Object.keys(utmValues)
    keys.forEach((key) => {
      params.delete(key)
    })

    // add new params
    keys.forEach((key) => {
      if (utmValues[key] && utmValues[key] !== '') {
        params.append(key, utmValues[key])
      }
    })
    url.search = params.toString()
    return url.toString()
  } catch (error) {
    return visibleLink.originalUrl
  }
}

export const converUtmConfig = (url: IUrl) => {
  return {
    utm_source: url?.utmConfig?.utm_source,
    utm_medium: url?.utmConfig?.utm_medium,
    utm_campaign: url?.utmConfig?.utm_campaign,
    utm_term: url?.utmConfig?.utm_term,
    utm_content: url?.utmConfig?.utm_content,
  }
}

//@refactoring da fare
export const setShortenedLink = (
  post: IPostData | IPostVariant,
  shortValue: boolean,
  visibleLink,
  firstComment?: boolean,
  isDescription?: boolean
) => {
  if (firstComment === undefined) {
    if ('_id' in post && post._id === 'GLOBAL') {
      const newUrls = (post as IPostData).urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shortValue }
        }
        return url
      })

      //@refactoring da fare
      const currentText = !isDescription ? getActiveText() : getActiveDescription()
      const valueToReplace = visibleLink.shortened ? visibleLink.shortUrl : visibleLink.originalUrl
      const newValue = visibleLink.shortened ? visibleLink.originalUrl : visibleLink.shortUrl

      const editedText = currentText?.replaceAll(valueToReplace, newValue)
      const updateValue = !isDescription ? { text: editedText } : { description: editedText }
      updateActive({ ...updateValue, urls: newUrls })
    }

    if ('variantId' in post) {
      const newUrls = post.data.urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shortValue }
        }
        return url
      })

      const currentText = !isDescription ? getActiveText() : getActiveDescription()
      const valueToReplace = visibleLink.shortened ? visibleLink.shortUrl : visibleLink.originalUrl
      const newValue = visibleLink.shortened ? visibleLink.originalUrl : visibleLink.shortUrl
      const editedText = currentText?.replaceAll(valueToReplace, newValue)
      const updateValue = !isDescription ? { text: editedText } : { description: editedText }
      updateActive({ ...updateValue, urls: newUrls })
    }
  }

  if (firstComment === true) {
    if ('variantId' in post) {
      const newUrls = post.data.firstComment?.urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shortValue }
        }
        return url
      })

      const actualLinkValue = visibleLink.shortened
        ? visibleLink.shortUrl
        : newUtmUrl(visibleLink, converUtmConfig(visibleLink))

      const newText = visibleLink.shortened
        ? getFirstCommentText(post)?.replaceAll(actualLinkValue, newUtmUrl(visibleLink, converUtmConfig(visibleLink)))
        : getFirstCommentText(post)?.replaceAll(actualLinkValue, visibleLink.shortUrl)

      updatePost(post.variantId, { firstComment: { text: newText, urls: newUrls } })
    }
  }
}

export const getLinkProperties = (post?: IPostVariant) => {
  if (post === undefined) {
    return getActiveLinkProperties()
  }
  if (post !== undefined) {
    return getLinkPropertiesFirstComment(post)
  }
}

export const getActiveLinkProperties = () => {
  const { activePost } = useCreatePostStore.getState()

  if (activePost === undefined) return []

  if ('_id' in activePost && activePost._id === 'GLOBAL') return (activePost as IPostData).urls ?? []

  if ('variantId' in activePost) return activePost.data.urls ?? []

  return []
}

export const getLinkPropertiesFirstComment = (post: IPostVariant) => {
  return post.data.firstComment?.urls ?? []
}

// Il parametro "post" viene passato solo quando si lavora in un post specifico nello specifico quando lavoriamo nel primo commento, altrimenti se si lavora nella sezione di preview si utilizza il post attivo
export const canShowMetadata = (post?: IPostVariant, previewPost?) => {
  if (post !== undefined) {
    const firstMetadata = findVisibleMetadata(post, true)
    const visibleLink = firstMetadata ? firstMetadata[0] : undefined

    return (
      post?.info?.account &&
      WHITELIST_URL_FUNCTIONALITIES_PROVIDERS?.includes(post.info.account.provider) &&
      visibleLink &&
      visibleLink.metaData.ogTitle !== undefined
    )
  }

  if (!post) {
    const { activePost } = useCreatePostStore.getState()

    const workingPost = previewPost ?? activePost

    const firstMetadata = findVisibleMetadata(workingPost)
    const visibleLink = firstMetadata ? firstMetadata[0] : undefined

    return (
      getActiveInfo(workingPost).account &&
      WHITELIST_URL_FUNCTIONALITIES_PROVIDERS.includes(getActiveInfo(workingPost).account.provider) &&
      visibleLink &&
      visibleLink.metaData.ogTitle !== undefined
    )
  }
}

/**
 * The function `isShortenEnable` checks if any posts have a corresponding social account with a
 * reference parameter.
 * @returns The function `isShortenEnable` is returning a boolean value. It is checking if there are
 * any posts where the associated social account has a `refParameter` property. If no such post is
 * found, it returns `true`, indicating that shorten is enabled. If at least one post with a
 * corresponding social account having a `refParameter` is found, it returns `false`, indicating that
 * shorten
 */
export const isShortenEnable = (): boolean => {
  const { posts } = useCreatePostStore.getState()
  if (posts.length === 0) return true
  return (
    AppStore.socialAccounts
      .filter((s) => !!s.refParameter)
      .filter((appstoreSocial) => posts.some((socialPost) => appstoreSocial._id === socialPost.info.account.account_id))
      .length === 0
  )
}

/**
 * The function `addRefParameterToDescription` takes a description, a reference parameter, and a URL
 * regex, and adds the reference parameter to URLs in the description that do not already have a 'ref'
 * parameter.
 * @param {string} description - The `addRefParameterToDescription` function takes in a `description`
 * string, a `refParameter` string, and a `urlRegex` regular expression as parameters. It replaces all
 * URLs in the `description` string that match the `urlRegex` pattern with the same URL but with an
 * @param {string} refParameter - The `refParameter` is a string that represents the reference
 * parameter that will be added to the URL in the description.
 * @param {RegExp} urlRegex - The `urlRegex` parameter is a regular expression used to match URLs
 * within the `description` string. This regex pattern is used to identify URLs that need to be
 * modified by adding a reference parameter.
 * @returns The function `addRefParameterToDescription` returns a modified version of the input
 * `description` string where any URLs matching the `urlRegex` pattern have a `ref` parameter appended
 * with the value of `refParameter`. If the URL already contains a `ref` parameter or 'ref=' in the
 * query string, it will not be modified. If the URL cannot be parsed as a valid URL
 */
export const addRefParameterToDescription = (description: string, refParameter: string, urlRegex: RegExp): string => {
  if (!refParameter) return description
  return description.replaceAll(urlRegex, (url) => {
    try {
      const urlTemp = new URL(url)
      if (!urlTemp.searchParams.has('ref') && !url.toString().includes('ref=')) {
        urlTemp.searchParams.append('ref', refParameter)
      }
      return urlTemp.toString()
    } catch (e) {
      return url
    }
  })
}

/**
 * The `filterUrl` function filters out URLs from a list that are already present in another list of
 * URLs.
 * @param {IUrl[]} postUrls - An array of objects of type IUrl, which contain information about post
 * URLs.
 * @param {string[]} urls - The `urls` parameter is an array of strings representing URLs that need to
 * be filtered based on a condition.
 * @returns The function `filterUrl` is returning an array of strings that are present in the `urls`
 * array but not present in the `postUrls` array of objects based on the condition that the
 * `originalUrl` property of each object in `postUrls` does not include the string `url`.
 */
export const filterUrl = (postUrls: IUrl[], urls: string[]): string[] => {
  return urls.filter((url) => postUrls.every((postUrl) => !postUrl.originalUrl.startsWith(url)))
}

/**
 * The function `getRefParameter` retrieves the reference parameter of a social account based on its
 * ID.
 * @param {string} _id - The `_id` parameter is a string that represents the unique identifier of a
 * social account.
 * @returns The function `getRefParameter` is returning the `refParameter` property of the social
 * account object that matches the provided `_id`. If a matching social account is found, it returns
 * the `refParameter` value. If no matching social account is found, it returns an empty string.
 */
export const getRefParameter = (_id: string): string => {
  return AppStore.socialAccounts.find((account) => account._id === _id)?.refParameter ?? ''
}

/**
 * The function `checkIfUrlAlreadyExists` checks if any of the given URLs already exist in a list of
 * stored URLs based on their prefixes.
 * @param {IUrl[]} storedUrls - An array of objects of type IUrl, which contain information about
 * stored URLs. Each object has a property 'originalUrl' that represents the original URL of the stored
 * link.
 * @param {string[]} urls - The `urls` parameter is an array of strings representing URLs that you want
 * to check for existence in the `storedUrls` array.
 * @returns A boolean value is being returned, indicating whether any of the URLs in the `urls` array
 * start with the `originalUrl` property of any object in the `storedUrls` array.
 */
export const checkIfUrlAlreadyExists = (storedUrls: IUrl[], urls: string[]): boolean => {
  return urls.every((url) => storedUrls.some((storedUrl) => url.startsWith(storedUrl.originalUrl)))
}

/**
 * The function `manageGlobalLinkUpdate` updates the URLs in posts by adding a reference parameter
 * based on social accounts.
 * @param {string[]} contentUrls - The `contentUrls` parameter is an array of strings that contains
 * URLs found in the content being edited by the user. These URLs are used to identify links that need
 * to be updated in the `posts` data.
 * @param {IPostVariant[]} posts - The `manageGlobalLinkUpdate` function takes in an array of
 * `contentUrls` and an array of `posts` of type `IPostVariant`. It iterates through each post and adds
 * a reference parameter to the URLs in the post data based on the social account associated with the
 * post.
 * @returns The function `manageGlobalLinkUpdate` is returning a Promise that resolves to an array of
 * `IPostVariant` objects after processing the input `contentUrls` and `posts`.
 */
export const manageGlobalLinkUpdate = async (
  contentUrls: string[],
  posts: IPostVariant[],
  urlRegex: RegExp
): Promise<IPostVariant[]> => {
  // If user is editing a global post and has social accounts added cycle through all the social accounts and add the ref parameter to the links
  const updatedPosts: IPostVariant[] = []
  await Promise.all(
    posts.map(async (post, index) => {
      // Get all the urls in the text that are not in the post datas
      const newUrls =
        !!post.data?.urls && post.data.urls.length > 0 ? filterUrl(post.data.urls, contentUrls) : contentUrls
      // If all urls are already in the post return
      if (newUrls.length === 0) {
        updatedPosts.push(post)
        return
      }

      // Get the ref parameter from the social account and start adding it to the urls
      const ref = getRefParameter(post.info.account.account_id)
      const urlsToSave: IUrl[] = []
      await Promise.all(
        newUrls.map(async (url) => {
          try {
            urlsToSave.push((await recoverShortenedLink(url, 'standard', ref)) as IUrl)
          } catch (error) {
            console.error('error adding ref parameter to the url', error)
          }
        })
      )

      // Save the new urls in the post
      index === 0 && updateActive({ urls: [...(post?.data?.urls ?? []), ...urlsToSave] })
      // Update the description with the new urls containing the ref parameter
      const newText = addRefParameterToDescription(post.data.text, ref, urlRegex)

      // Update the post data
      updatedPosts.push({
        ...post,
        data: { ...post.data, text: newText, urls: [...(post.data.urls ?? []), ...urlsToSave] },
      })
    })
  )
  return updatedPosts
}

/**
 * The function `manageVariantLinkUpdate` updates post URLs with a reference parameter and filters out
 * URLs not present in the post description.
 * @param {string[]} contentUrls - The `contentUrls` parameter is an array of strings that contains the
 * URLs found in the content that you want to update.
 * @param {IPostVariant} activePost - The `activePost` parameter in the `manageVariantLinkUpdate`
 * function represents the currently active post variant. It is of type `IPostVariant` and contains
 * information about the post, such as data, urls, and info related to the post's account. This
 * parameter is used to filter and
 * @param {RegExp} urlRegex - The `urlRegex` parameter in the `manageVariantLinkUpdate` function is a
 * regular expression used to match and manipulate URLs in the description of a post. It helps in
 * identifying and updating URLs in the post description based on a specific pattern defined by the
 * regular expression.
 * @param {string} description - The `description` parameter in the `manageVariantLinkUpdate` function
 * is a string that represents the text content of a post. This description may contain URLs that need
 * to be updated with a reference parameter before being posted. The function processes these URLs,
 * adds a reference parameter to them, and updates the
 * @returns The `manageVariantLinkUpdate` function is returning a `Promise<void>`.
 */
export const manageVariantLinkUpdate = async (
  contentUrls: string[],
  activePost: IPostVariant,
  urlRegex: RegExp,
  description: string
): Promise<void> => {
  if (contentUrls.length === activePost.data.urls?.length ?? 0) return
  // filter the urls that are not in the new post description
  activePost.data.urls = activePost.data?.urls?.filter((url: IUrl) => contentUrls.includes(url.originalUrl)) ?? []

  // Get all the urls in the text that are not in the post datas
  const newUrls = filterUrl(activePost.data.urls, contentUrls)
  // console.log('newUrls', newUrls, activePost.data.urls)
  if (newUrls.length === 0) return
  // get the ref parameter from the social account
  const ref = getRefParameter(activePost.info.account.account_id)
  // console.log('ref', ref)
  // add the ref parameter to the urls
  try {
    const newLinks: IUrl[] = []
    newUrls.length > 0 &&
      (await Promise.all(
        newUrls.map(async (url) => {
          try {
            const response = (await recoverShortenedLink(url, 'standard', ref)) as IUrl
            newLinks.push(response)
          } catch (error) {
            console.error('error adding ref parameter to the url', error)
          }
        })
      ))
    // Update description with the new urls
    const newDescription = addRefParameterToDescription(description, ref, urlRegex)

    // update the post in the store
    updateActive({
      text: newDescription,
      urls: [...activePost.data.urls.filter((u) => contentUrls.includes(u.originalUrl)), ...newLinks],
    })
    return
  } catch (error) {
    console.error('error adding ref parameter to the url', error)
  }
  return
}
